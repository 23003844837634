<!-- this scss on _cards -->
<template>
  <div>
    <dashboard-navbar :routes="routes"></dashboard-navbar>
    <div class="content" @click="$sidebar.displaySidebar(false)">
      <div class="container-fluid mt-2">
        <section class="card-student">
          <div class="wrapper-heading">
            <h2 class="heading-card-student">Detail Student Profile</h2>
          </div>

          <section class="card-student-body">
            <loading-panel v-if="loading.get_detail" />
            <validation-observer
              v-else-if="!loading.get_detail && !error.get_detail"
              v-slot="{ handleSubmit }"
              ref="formValidator"
            >
              <form role="form">
                <div class="wrapper-photo">
                  <div class="photo-student">
                    <img class="photo" :src="account.photo" alt="photo Student" />
                  </div>
                  <base-button
                    size="sm"
                    outline
                    type="warning"
                    class="button-delete"
                    @click="handleDeleteImage"
                  >Delete Photo</base-button>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <base-input
                      label="Name"
                      class="mb-3"
                      name="Name"
                      placeholder="-"
                      v-model="account.name"
                      :disabled="true"
                    ></base-input>
                  </div>
                  <div class="col-md-6">
                    <base-input
                      label="Specialist"
                      class="mb-3"
                      name="Specialist"
                      placeholder="-"
                      v-model="account.specialist.name"
                      :disabled="true"
                    ></base-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <base-input
                      label="Gender"
                      class="mb-3"
                      name="Gender"
                      placeholder="-"
                      v-model="account.gender"
                      :disabled="true"
                    ></base-input>
                  </div>
                  <div class="col-md-6">
                    <base-input
                      label="NPA IDI"
                      class="mb-3"
                      name="NPA IDI"
                      placeholder="-"
                      v-model="account.npaidi"
                      :disabled="true"
                    ></base-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <base-input
                      label="Email"
                      class="mb-3"
                      name="Email"
                      placeholder="-"
                      v-model="account.email"
                      :disabled="true"
                    ></base-input>
                  </div>
                  <div class="col-md-6">
                    <base-input
                      label="Telephone Number"
                      class="mb-3"
                      name="Telephone"
                      placeholder="-"
                      v-model="account.nomor_tlp"
                      :disabled="true"
                    ></base-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <base-input
                      label="Institute"
                      class="mb-3"
                      name="Institute"
                      placeholder="-"
                      v-model="account.institute"
                      :disabled="true"
                    ></base-input>
                  </div>
                </div>
                <div class="text-right">
                  <base-button outline type="primary" class="my-4" @click="handleBack">Back</base-button>
                  <base-button
                    v-if="!account.status"
                    type="primary"
                    class="my-4"
                    @click="showAddModal = true"
                  >Active</base-button>
                  <base-button
                    v-else
                    type="warning"
                    class="my-4"
                    @click="showAddModal = true"
                  >Nonactive</base-button>
                </div>
                <modal :show.sync="showAddModal" modal-classes="modal-secondary">
                  <template slot="header">
                    <h6 v-if="!account.status" class="modal-title">Active Profile</h6>
                    <h6 v-else class="modal-title">Nonactive Profile</h6>
                  </template>
                  <template slot="close-button"></template>
                  <span class="body-modal">
                    Are you sure to
                    <span class="active" v-if="!account.status">Active</span>
                    <span class="not-active" v-else>nonactive</span>
                    this student profile?
                  </span>
                  <template slot="footer">
                    <div class="text-right">
                      <base-button
                        outline
                        type="primary"
                        class="my-4"
                        @click="showAddModal = false"
                      >Back</base-button>
                      <base-button
                        v-if="!account.status"
                        type="primary"
                        class="my-4"
                        @click="handleStatus"
                      >Active</base-button>
                      <base-button
                        v-else
                        type="warning"
                        class="my-4"
                        @click="handleStatus"
                      >Nonactive</base-button>
                    </div>
                  </template>
                </modal>
              </form>
            </validation-observer>
          </section>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardNavbar from "@/Layout/DashboardNavbar.vue";
import { getDetailMember, ActiveOrNonActive, deletePhoto } from '@/services/student'
import Modal from "@/components/Modal";
import LoadingPanel from '@/components/LoadingPanel.vue';
export default {
  components: {
    Modal,
    DashboardNavbar,
    LoadingPanel
  },
  data() {
    return {
      routes: [
        {
          name: "ListStudents",
          alias: "LIST STUDENTS"
        },
        {
          name: "ListStudentsId",
          alias: "DETAIL STUDENT PROFILE"
        }
      ],
      account: {
        id: '',
        email: '',
        gender: '',
        institute: '',
        name: '',
        nomor_tlp: '',
        npaidi: '',
        specialist: {
          name: '',
          id: ''
        },
        username: '',
        status: 0,
        photo: null,
      },
      showAddModal: false,
      loading: {
        get_detail: false,
        del_photo: false,
      },
      error: {
        get_detail: false,
      },

    };
  },
  methods: {
    handleBack() {
      this.$router.push({ name: "ListStudents" });
    },
    async getDetail() {
      try {
        this.loading.get_detail = true
        this.error.get_detail = false
        const { data } = await getDetailMember(this.$route.params.id)
        this.loading.get_detail = false
        this.account = data
      } catch (error) {
        this.error.get_detail = true
        this.$notify({
          type: "danger",
          verticalAlign: "top",
          horizontalAlign: "center",
          closeOnClick: true,
          message: error
        });
      }
    },
    async handleStatus() {
      try {
        const payload = {
          id: this.account.id,
          // status: this.account.status,
        }
        const { data } = await ActiveOrNonActive(payload)
        this.account.status = !data.status
        this.showAddModal = false
        this.$notify({
          type: "success",
          verticalAlign: "top",
          horizontalAlign: "center",
          closeOnClick: true,
          message: "Success change status student"
        });
      } catch (error) {
        this.$notify({
          type: "danger",
          verticalAlign: "top",
          horizontalAlign: "center",
          closeOnClick: true,
          message: error
        });
      }
    },
    async handleDeleteImage() {
      try {
        await deletePhoto(this.$route.params.id)
        this.$notify({
          type: "success",
          verticalAlign: "top",
          horizontalAlign: "center",
          closeOnClick: true,
          message: "Success delete photo"
        });
        this.account.photo = '/img/default_picture.png'
      } catch (error) {
        if (error.code === 401) {
          this.$cookies.remove('CERT');
          this.$store.dispatch('auth/removeAuth');
          this.$router.replace({ name: "Login" })
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error.msg
          });
        } else {
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error
          });
        }
      }
    }
  },
  created() {
    this.getDetail()
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/sass/argon.scss";
.card-student-body {
  background-color: #fff;
  position: relative;
  padding: 20px;

  .wrapper-photo {
    display: flex;
    height: 120px;
    align-items: center;
    justify-content: center;

    .photo-student {
      position: absolute;
      transform: translate(0, -50%) scale(1);
      height: 170px;
      width: 170px;
      border-radius: 100px;
      background: rgb(175, 174, 174);
      overflow: hidden;

      .photo {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    .button-delete {
      margin-top: 45px;
    }
  }
}
</style>